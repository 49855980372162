<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 256 256"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_105_24)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M56.56 249.908C49.2104 242.264 49.4487 230.11 57.0923 222.76L157.899 127.8L57.0923 32.84C49.4487 25.4904 49.2104 13.336 56.56 5.69235C63.9097 -1.95125 76.0641 -2.18958 83.7077 5.16005L198.908 113.96C202.672 117.58 204.8 122.577 204.8 127.8C204.8 133.023 202.672 138.02 198.908 141.64L83.7077 250.44C76.0641 257.79 63.9097 257.551 56.56 249.908Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_105_24">
        <rect
          width="256"
          height="256"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
